<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="12" class="d-flex flex-row">
            <v-img src="./assets/logo.png"
              max-width="64" max-height="64"
              class="d-none d-sm-flex">
            </v-img>
            <div class="d-flex flex-column ml-sm-4">
              <h1 class="title">
                {{app.title}}
              </h1>
              <span class="subtitle">
                Простой в использовании сервис создания пароля
              </span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-card>
              <v-card-title>
                Настройки
              </v-card-title>
              <v-card-text>
                <v-checkbox v-model="settings.lowercase"
                  :disabled="isLoading"
                  label="Английские маленькие буквы (a-z)"></v-checkbox>
                <v-checkbox v-model="settings.uppercase"
                  :disabled="isLoading"
                  label="Английские большие буквы (A-Z)"></v-checkbox>
                <v-checkbox v-model="settings.numbers"
                  :disabled="isLoading"
                  label="Цифры (0-9)"></v-checkbox>
                <v-checkbox v-model="settings.symbols"
                  :disabled="isLoading"
                  label="Специальные символы"></v-checkbox>
                <v-text-field label="Длина пароля" color="primary" type="number"
                  :min="allowedPasswordLength.min" :max="allowedPasswordLength.max"
                  :disabled="isLoading" v-model="passwordLength"></v-text-field>
                <v-btn-toggle v-model="passwordLength" class="overflowable" group>
                  <v-btn v-for="length in predefinedPasswordLengths"
                    :key="length" :value="length"
                    :disabled="isLoading" small>
                      {{length}}
                    </v-btn>
                </v-btn-toggle>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="8">
            <v-card :loading="isLoading">
              <v-card-title>
                Пароли
              </v-card-title>
              <v-card-actions>
                <v-btn @click="generatePasswords()" :disabled="nothingSettingsSelected"
                  :loading="isLoading" color="primary" small>
                  Сгенерировать
                </v-btn>
              </v-card-actions>
              <v-card-text>
                <template v-if="isPasswordsEmpty">
                  Здесь появятся сгенерированные пароли
                </template>
                <template v-else>
                  <ol>
                    <li v-for="password in passwords" :key="password">
                      <code>
                        {{password}}
                      </code>
                      <v-btn @click="copyPasswordToClipboard(password)" color="primary"
                        class="ml-2" icon x-small>
                        <v-icon>
                          {{icons.mdiContentCopy}}
                        </v-icon>
                      </v-btn>
                    </li>
                  </ol>
                  <v-snackbar v-model="checkers.passwordCopied.show" timeout="4000">
                    <template v-if="checkers.passwordCopied.state">
                      Пароль скопирован в буфер обмена
                    </template>
                    <template v-else>
                      Не удалось скопировать пароль в буфер обмена
                    </template>
                  </v-snackbar>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <!-- Yandex.RTB R-A-613070-1 -->
            <div id="yandex_rtb_R-A-613070-1"></div>
            <script>window.yaContextCb.push(()=>{
              Ya.Context.AdvManager.render({
                renderTo: 'yandex_rtb_R-A-613070-1',
                blockId: 'R-A-613070-1'
              })
            })</script>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer padless>
      <v-card width="100%" outlined tile>
        <v-card-text>
          В качестве логотипа сервиса используется <a href="https://icons8.ru/icons/set/forgot-password" target="_blank">значок, скачанный с Icons8.ru</a>
        </v-card-text>
        <v-card-text>
          <!-- eslint-disable-next-line -->
          Пароли, созданные в процессе использования сервиса, никак и нигде не сохраняются и доступны только один раз
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="d-flex flex-row justify-space-between">
          <span>
            &copy; {{year}} <a href="//zkelo.ru" target="_blank">ZKelo</a>
          </span>
          <span>
            <i>Версия: {{app.version}}</i>
          </span>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import passwordGenerator from 'generate-password';
import { mdiContentCopy } from '@mdi/js';

export default {
  name: 'App',
  data: () => ({
    app: {
      title: 'Мой пароль',
      version: '1.1.4',
    },
    checkers: {
      passwordCopied: {
        show: false,
        state: true,
      },
    },
    loading: false,
    rules: {
      passwordLength: {
        min: 8,
        max: 64,
      },
    },
    settings: {
      passwordLength: 8,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: false,
    },
    passwords: [],
  }),
  methods: {
    generatePasswords(count = 10) {
      this.setLoading(true);
      this.passwords.splice(0, this.passwords.length);

      const options = {
        excludeSimilarCharacters: true,
        length: this.settings.passwordLength,
        lowercase: this.settings.lowercase,
        uppercase: this.settings.uppercase,
        numbers: this.settings.numbers,
        symbols: this.settings.symbols,
      };

      const passwords = passwordGenerator.generateMultiple(count, options);
      passwords.forEach((password) => {
        this.passwords.push(password);
      });

      this.setLoading(false);
    },
    copyPasswordToClipboard(password) {
      this.checkers.passwordCopied.show = true;
      this.checkers.passwordCopied.state = this.$clipboard(password);
    },
    setLoading(status) {
      this.loading = !!status;
    },
    saveStorageData() {
      const meta = {
        version: this.app.version,
      };

      localStorage.meta = JSON.stringify(meta);
      localStorage.settings = JSON.stringify(this.settings);
    },
    loadStorageData() {
      if (typeof localStorage.meta === 'undefined') {
        this.saveStorageData();
      } else {
        try {
          const meta = JSON.parse(localStorage.meta);
          if (typeof meta.version !== 'undefined') {
            if (meta.version !== this.app.version) {
              this.saveStorageData();
            }
          } else {
            this.saveStorageData();
          }
        } catch (exception) {
          this.saveStorageData();
        }

        if (typeof localStorage.settings === 'undefined') {
          this.saveStorageData();
        } else {
          try {
            JSON.parse(localStorage.settings, (key, value) => {
              if (typeof this.settings[key] !== 'undefined') {
                this.settings[key] = value;
              }
            });
          } catch (exception) {
            this.saveStorageData();
          }
        }
      }
    },
  },
  computed: {
    title() {
      return this.title;
    },
    icons() {
      return {
        mdiContentCopy,
      };
    },
    isLoading() {
      return this.loading;
    },
    isPasswordsEmpty() {
      return this.passwords.length === 0;
    },
    allowedPasswordLength() {
      return this.rules.passwordLength;
    },
    passwordLength: {
      get() {
        return this.settings.passwordLength;
      },
      set(value) {
        let newValue = value;
        if (newValue < this.allowedPasswordLength.min) {
          newValue = this.allowedPasswordLength.min;
        } else if (newValue > this.allowedPasswordLength.max) {
          newValue = this.allowedPasswordLength.max;
        } else if (typeof newValue === 'undefined') {
          newValue = this.passwordLength;
        }
        this.settings.passwordLength = +newValue;
      },
    },
    predefinedPasswordLengths() {
      return [
        8,
        10,
        16,
        24,
        32,
        64,
      ];
    },
    nothingSettingsSelected() {
      let num = 0;

      if (this.settings.lowercase) {
        num += 1;
      }
      if (this.settings.uppercase) {
        num += 1;
      }
      if (this.settings.numbers) {
        num += 1;
      }
      if (this.settings.symbols) {
        num += 1;
      }

      return num === 0;
    },
    year() {
      const date = new Date();
      return date.getFullYear();
    },
  },
  watch: {
    settings: {
      handler() {
        this.saveStorageData();
      },
      deep: true,
    },
  },
  mounted() {
    this.loadStorageData();
  },
};
</script>

<style lang="scss" scoped>
.overflowable {
  overflow: auto;
}
</style>
